<template>
  <div class="">
    <div class="col-12">
      <div class="card">
        <slot name="start">
          <Toolbar class="tw-mb-8 tw-p-3">
            <template #start>
              <div>
                <Button
                  label="Tambah"
                  icon="pi pi-plus"
                  class="p-button-sm p-button-success tw-bg-blue-light"
                  @click="navigateToCreate"
                />
              </div>
            </template>
          </Toolbar>
        </slot>
        <div class="text-xl font-medium text-black mb-3">
          <p>Pegawai</p>
        </div>
        <DataTable
          :value="barber"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          filterDisplay="menu"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'no',
            'salon_name',
            'name',
            'position',
            'gender',
            'status_name',
            'hide_rating',
          ]"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="perPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Hapus Filter"
                class="p-button-outlined mb-2"
                @click="clearFilter()"
              />
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Kata kunci"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
          Tidak ada data yang ditemukan
        </div>
      </template>
      <template #loading>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
          Loading records, please wait...
        </div>
      </template>
          <Column header="No." filterField="no" style="min-width: 2rem">
            <template #body="{ data }">
              {{ data.no }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <Column
            header="Nama Salon"
            filterField="salon_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">{{ data.salon_name }} </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by salon name"
              />
            </template>
          </Column>
          <Column field="name" header="Nama Pegawai" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by name"
              />
            </template>
          </Column>
          <Column field="position" header="Posisi" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.position ? data.position : `-`  }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by position"
              />
            </template>
          </Column>
          <Column field="gender" header="Jenis Kelamin" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.gender ? data.gender : `-`}}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by gender"
              />
            </template>
          </Column>
          <Column field="status_name" header="Status" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.status_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by status"
              />
            </template>
          </Column>
          <Column
            field="hide_rating"
            header="Sembunyikan Rating"
            style="min-width: 10rem"
          >
            <template #body="{ data }">
              {{ data.hide_rating }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by hide rating"
              />
            </template>
          </Column>
          <Column
            header="Aksi"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '14rem' }"
            style="min-width: 14rem"
          >
            <template #body="{ data }">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info"
                @click="detailData(data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import BarberService from "@/service/BarberService";
export default {
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      barber: [],
      filters1: null,
    };
  },
  barbersService: null,
  created() {
    this.barbersService = new BarberService();
    this.initFilters();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-barber" });
    },
    detailData(id) {
      this.$router.push({ name: "detail-barber", params: { id: id } });
    },
    loadData() {
      this.barbersService.getAll().then((response) => {
        if (response.code === 200) {
          this.barber = response.data.barbers;
          const temp = [];
          this.barber.forEach((barbers, idx) => {
            temp.push({ ...barbers, no: idx + 1 });
          });
          this.barber = temp;
        }
      });
    }, 
    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        salon_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
         name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
         position: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
         gender: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
         status_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
         hide_rating: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
  },
};
</script>

<style scoped lang="scss"></style>
