import axios from "axios";

const BARBER_URL = 'api/v1/barbers'

export default class BarberService {
    getAll(queryParam) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/list`, {params: queryParam}).then(res => res.data)
    }

    getOne(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${id}`).then(res => res.data)
    }

    create(data) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}`, data).then(res => res.data)
    }

    update(id, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${id}`, data).then(res => res.data)
    }

    getBarberService(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/services`, {params: {barber_id: id}}).then(res => res.data)
    }

    createBarberService(barberId, data) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${barberId}/services`, data).then(res => res.data).catch(e => e.response.data)
    }

    updateBarberService(barberId, barberServiceId, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${barberId}/services/${barberServiceId}`, data).then(res => res.data).catch(e => e.response.data)
    }

    deleteBarberService(id) {
        return axios.delete(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/services/${id}`).then(res => res).catch(e => e.response)
    }

    getBarberAvailability(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/availability/ddl`, {params: {barber_id: id}}).then(res => res.data)
    }

    createBarberAvailability(data) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/availability`, data).then(res => res.data)
    }

    createBarberAvailabilityBulk(id, data) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${id}/bulk-barber-availability`, data).then(res => res.data)
    }

    updateBarberAvailability(barberId, barberAvailabilityId, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${barberId}/availability/${barberAvailabilityId}`, data).then(res => res.data)
    }

    deleteBarberAvailability(id) {
        return axios.delete(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/availability/${id}`).then(res => res.data).catch(e => e.response.data)
    }

    getBarberInfos(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/${id}/barberinfo`).then(res => res.data)
    }

    createBarberInfo(data) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/barberinfo`, data).then(res => res.data)
    }

    updateBarberInfo(barberInfoId, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/barberinfo/${barberInfoId}`, data).then(res => res.data)
    }

    deleteBarberInfo(barberInfoId) {
        return axios.delete(`${process.env.VUE_APP_PRITI_MAIN_API}/${BARBER_URL}/barberinfo/${barberInfoId}`)
    }
}
